import './styles/App.css';
//import nftLogoSmall from './assets/logo-small.png';
import nftLogo from './assets/mint-box-logo.png';
import featureBG from './assets/feature-bg.jpg';
import NFT1 from './assets/Ooglies-1.jpg';
import NFT2 from './assets/Ooglies-2.jpg';
import NFT3 from './assets/Ooglies-3.jpg';

import { ethers } from "ethers";

import React, { useEffect, useState } from "react";

import { Rings,MutatingDots } from  'react-loader-spinner'

import nftABI from './utils/Contract.json';

const spinnerColor = "#ffffff";

const publicSaleLive = true;

const publicPrice = 0.00666;

const MAX_SUPPLY = 3333;

const CONTRACT_ADDRESS = "0xfF05491B0Bd7d47F8A030E27ec191468A0f92d38";

const App = () => {

  const [currentAccount, setCurrentAccount] = useState(false);
  const [correctNetwork, setCorrectNetwork] = useState(false);
  const [amountToMint,setAmountToMint] = useState(1);

  const [userLevel,setUserLevel] = useState('public');

  const [mintStatus,setMintStatus] = useState('');
  const [walletStatus,setWalletStatus] = useState('wallet-disconnected');
  const [noticeText,setNoticeText] = useState(false);

  const [ethAddress,setETHAddress] = useState(null);

  const [isSoldOut,setIsSoldOut] = useState(false);


  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
        setNoticeText("You need to install Metamask! If you're on mobile, open this page using the Metamask app.");
        return;
      } 
    else {
        //setNoticeText("We have the ethereum object.");
      }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const accountCaseSensitive = ethers.utils.getAddress(accounts[0]);

      // Get .eth address
      const provider = new ethers.providers.Web3Provider(ethereum);
      const ethAddress = await provider.lookupAddress(accountCaseSensitive);

      setCurrentAccount(accountCaseSensitive);
      setETHAddress(ethAddress);
      buttonSetUserLevel(accountCaseSensitive);

      setWalletStatus("wallet-connected");
      } 
    else {
      //setNoticeText("No authorized account found");
      }
  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        setNoticeText("You need to install Metamask! Or, if you're on mobile and Metamask is already installed, you must open this page using the browser within the Metamask app.");
        return;
      }

      setWalletStatus("connecting-wallet");

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      const accountCaseSensitive = ethers.utils.getAddress(accounts[0]);

      setCurrentAccount(accountCaseSensitive);
      buttonSetUserLevel(accountCaseSensitive);
      setWalletStatus("wallet-connected");
      setNoticeText(false);

      // Is returning user's wallet already connected?
      //setupEventListener() 
      }
    catch (error) {
      setNoticeText(error.message);
      setWalletStatus("wallet-disconnected");
      setMintStatus("");
      }
    }
  
  // Checks if wallet is connected to the correct network
  const checkCorrectNetwork = async () => {
    
    const { ethereum } = window
    let chainId = await ethereum.request({ method: 'eth_chainId' })
    //console.log('Connected to chain:' + chainId)

    /* if (chainId !== '0x1') { rinkeby */
    /* if (chainId === '0x1') { mainnet */
    if (chainId === '0x1') { // mainnet
    setCorrectNetwork(true);
    } else {
        setCorrectNetwork(false);
        setNoticeText("Connect to Ethereum Mainnet and reload the page!");
    }
  }
      



  /* Mint Functions */
  const publicMint = () => mintNFT('public');

  const mintNFT = async (_accessLevel,_proof=null) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        let _price;

        _price = publicPrice;
        
        let _mintTotal = (_price * 1000000 * amountToMint / 1000000).toString();
        const _options = {value: ethers.utils.parseEther(_mintTotal)}

        //console.log(_proof);
        //setNoticeText("Opening wallet...");

        setMintStatus("minting");

        let nftTxn;

        nftTxn = await connectedContract.publicMint(amountToMint,_options);
        
        setNoticeText("Waiting for transaction confirmation...")
        await nftTxn.wait();
        
        setMintStatus("minted");
        setNoticeText(`Success!<br />View your mints on <a href="https://opensea.io/account/" rel="noreferrer" target="_blank">OpenSea</a>!</a>`)

      } else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } catch (error) {
      //console.log(error);
      if (error.code === 'INSUFFICIENT_FUNDS') {
        setNoticeText('Insufficient funds to complete this transaction!')
        setMintStatus("error");
        }
      else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
        setNoticeText(`Whoops, the mint hasn't started yet!`);
        setMintStatus("error");
      }
      else {
        setNoticeText(error.message);
        setMintStatus("error");
      }
    }
  }
  /* END Mint Functions */



  /* Set Functions */
  // Passing _currentWallet to allow calling before global currentAccount is set
  const buttonSetUserLevel = (_currentWallet = currentAccount) => {
    setUserLevel('public');
  };
  /* END Set Functions */



  /* Render Frontend */
  const renderSalesInactive = () => (
    <>
      <div className="entrance">
        <div className={"mint-columns " + userLevel}>
          <div className="column-price column">
            <div className="mintPrice">
              
            </div>
          </div>
          <div className="column-price column">
            <div className="mintPrice">
              <div className="userLevelMintPrice">
                Public Price
                <div className="userLevelMintPriceWrap">{publicPrice}</div>
              </div>
            </div>
          </div>
        </div>
        <button disabled>Minting Soon!</button>
        </div>
    </>
  );
  
  
  const renderWrongNetwork = (
    <div className="loading-wrong-network">
      <div className="loading-wrong-network-rings">
        <Rings color={spinnerColor} height={120} width={120} /><br />
      </div>
      <div className="loading-wrong-network-text">{noticeText}</div>
    </div>
  );

  const renderNotConnectedButton = () => (
    <button onClick={connectWallet} className="cta-button connect-wallet-button">Connect Wallet</button>
  );

  /*const renderSoldOutContainer = () => (
    <>
      <div className="sold-out">
        Sold Out!
      </div>
    </>
  );*/

  const renderConnectedButton = () => (
    <><button className="cta-button connect-wallet-button logged-in">{renderShortAddress()}</button></>
  );

  const renderNotConnectedNav = () => (
    <button onClick={connectWallet} className="cta-button connect-wallet-button">Connect Wallet</button>
  );

  const renderMintCountSelect = (
    <div className="mintCountWrapper">
      <select name="mintCount" id="mintCount" className="mintCount" onChange={event => setAmountToMint(event.target.value)}>
        <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="8">8</option><option value="9">9</option><option value="10">10</option>
        <option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option>
        <option value="21">21</option><option value="22">22</option><option value="23">23</option><option value="24">24</option><option value="25">25</option>
        </select>
      </div>
  );

  const renderTotal = () => {
    return publicPrice * 1000000 * amountToMint / 1000000;
  };

  const getUserLevelPrice = (_userLevel = userLevel) => {
    return publicPrice;
  };

  const renderMintButton = (
    <button onClick={publicMint} className="cta-button connect-wallet-button">Mint Now!</button>
  );

  const renderMintButtons = (
    <div className="mintWrapper">
      <div className="loading"><MutatingDots color={spinnerColor} secondaryColor={spinnerColor} height={120} width={120} /></div>
      {
        (publicSaleLive ? 
          <>
          <div className={"mint-columns " + userLevel}>
            <div className="column-select column">{renderMintCountSelect}</div>
            <div className="column-price column">
              <div className="mintPrice">
                <div className="userLevelMintPrice">
                  Price
                  <div className="userLevelMintPriceWrap">{getUserLevelPrice()}</div>
                </div>
              </div>
            </div>
            <div className="column-total column">{renderTotal()}</div>
          </div>
          <div className="notice">{(noticeText !== false ? <div className="notice-text" dangerouslySetInnerHTML={{__html: noticeText}}></div> : "")}</div>
          {renderMintButton}
          </> : 
            <div className="entrance">
            <div className="loading"><MutatingDots color={spinnerColor} secondaryColor={spinnerColor} height={120} width={120} /></div>
            {renderWrongNetwork}
            <div className={"mint-columns " + userLevel}>
              <div className="column-price column">
                <div className="mintPrice">

                </div>
              </div>
              <div className="column-price column">
                <div className="mintPrice">
                  <div className="userLevelMintPrice">
                    Price
                    <div className="userLevelMintPriceWrap">{publicPrice}</div>
                    {publicSaleLive === true ? 'Sale Open' : 'Opening Soon'}
                  </div>
                </div>
              </div>
            </div>
            <div className="notice">{(noticeText !== false ? <div className="notice-text" dangerouslySetInnerHTML={{__html: noticeText}}></div> : "")}</div>
            <div className="saleClosed">The sale isn't open yet!</div>
            </div>
        )
      }
      </div>
  );

  const renderMintUI = () => (
    <>
    <div className="entrance">
    {renderWrongNetwork}
    {renderMintButtons}
    </div>
    </>
  );


  const checkIfSoldOut = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        var totalMinted = await connectedContract.totalSupply();

        if (parseInt(totalMinted.toNumber()) >= MAX_SUPPLY) { setIsSoldOut(true); }
        
      }
      else {
        console.log("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    checkIfWalletIsConnected();
    checkCorrectNetwork();
    checkIfSoldOut();
  });

  const renderShortAddress = () => {
    if (ethAddress !== null) {
      return ethAddress;
    }
    else {
      return currentAccount.toString().substr(0,6) + '\u2026' + currentAccount.toString().substr(-4)
    }
  };


  return (
    <div className={`App ${walletStatus} ${mintStatus}${correctNetwork === false ? ' wrong-network' : publicSaleLive !== true ? ' countdown' : ''}`} style={{ backgroundImage: `url(${featureBG})` }}>
      <div className="site-header">
        <div className="wrap">
          <div className="header-left">
            <a href="/">The Ooglies</a>
          </div>
          <div className="header-right">
            {correctNetwork === false ? '' : currentAccount === false ? renderNotConnectedNav() : renderConnectedButton()}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="header-container">
          <div className="large-logo" id="mint"><img src={nftLogo} alt="The Ooglies" /></div>
          {isSoldOut === true ? null : publicSaleLive === true ? currentAccount === false ? renderNotConnectedButton() : renderMintUI() : renderSalesInactive()}
        </div>
        <div className="content-section content-section-1">
          <div className="wrap story" id="story">
          <h2>The Ooglies Story</h2>
            <p><img src={NFT1} className="align-right" alt="Ooglie" />The Ooglies show up every Halloween and terrorize towns looking for sweet sweet ETH candy.</p>
            <p>The Ooglies are desperate to mimic human culture on Halloween and want to trick or treat, but don’t really understand human formalities. So the lines of tricking and treating are really thin.</p>
            <p><img src={NFT2} className="align-left" alt="Ooglie" />Bad news, if an Ooglie knocks on your door, it means that you’ve now crossed over into the inbetween.</p>
            <p>The inbetween is a time on Halloween when lines between all realms are the thinnest. You are no longer in our realm, and no longer in the Ooglies realm.</p>
            <p><img src={NFT3} className="align-right" alt="Ooglie" />During the inbetween, Ooglies are free to trick or treat. Tricking and treating are amongst a similar line for these creatures so be prepared for anything.</p>
            <p>To tell if you are in the inbetween realm, words will look like they are in a mirror. Everything will be backwards and inverted!</p>
          </div>
        </div>
        <div className="content-section content-section-2">
        <div className="wrap">
          <h2>Team</h2>
          <div className="column-wrap the-team">
            <div className="column">
              <div className="block-image"><figure><a href="https://twitter.com/thebadfroot" target="_blank" rel="noreferrer"><img src="https://badfroot.com/wp-content/uploads/sites/11/2022/01/Badfroot-Jack-Davidson-1.jpg" alt="Badfroot aka Jack Davidson" width="375" height="375" /></a></figure></div>
              <h4 id="badfroot-jack-davidson">Badfroot (Jack Davidson)</h4>
              <p><em>Artist/Story</em></p>
              <p>Jack grew up on the East End of Long Island, New York. Before he became a full-time artist he did sales and marketing, sold real estate in the Hamptons, and worked part-time in a group home for underprivileged and incarcerated youth. When he learned about NFTs from one of his close friends he gave up his career in sales, dove headfirst into learning how the space worked, and eventually created SkullKids.</p>
            </div>
            <div className="column">
              <div className="block-image"><figure><a href="https://twitter.com/jeffSARRIS" target="_blank" rel="noreferrer"><img src="https://badfroot.com/wp-content/uploads/sites/11/2022/01/Jeff-Sarris.jpg" alt="" width="375" height="375" /></a></figure></div>
              <h4 id="jeff-sarris">Jeff Sarris</h4>
                <p><em>Developer</em></p>
                <p>As cofounder of <a href="https://spyr.me" target="_blank" rel="noreferrer">SPYR</a>, Jeff's spent the last decade-plus behind the scenes helping entrepreneurs develop their brands and businesses. His most recognized work is with The Minimalists and on the Netflix documentary, <em>Minimalism</em>. He's also the host of several podcasts: <strong>Starting Now</strong>, where he dives into entrepreneur origin stories and <strong>Kidney Stone Diet</strong>. Watch on <a href="https://youtube.com/jeffsarris" rel="noreferrer" target="_blank">YouTube</a> or listen wherever you get your podcasts.</p>
            </div>
          </div>
        </div>
        </div>
        <div className="site-footer">
          <div className="wrap">
            &copy;{new Date().getFullYear()} <a href="https://badfroot.com" rel="noreferrer" target="_blank">Badfroot</a><br />
            NFT Development by <a href="https://ryps.co" rel="noreferrer" target="_blank">RYPS</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;